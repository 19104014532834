.header-h1-bold-rounded {
  /* Header/H1 - Bold - Gotham Rounded */
  font-family: 'GothamRounded' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}
//a one liner
.header-h2-bold-rounded {
  /* Header/H2 - Bold - Gotham Rounded */
  font-family: 'GothamRounded' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.25px;
}

.header-h3-bold-rounded {
  /* Header/H3 - Bold - Gotham Rounded */
  font-family: 'GothamRounded' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.15px;
}

.header-h4-bold-rounded {
  /* Header/H4 - Bold - Gotham Rounded */
  font-family: 'GothamRounded' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.header-h4-medium-rounded {
  /* Header/H4 - Medium - Gotham */
  font-family: 'GothamRounded' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.header-h4-medium {
  /* Header/H4 - Medium - Gotham */
  font-family: 'Gotham' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.header-h5-bold-rounded {
  /* Header/H5 - Bold - Gotham Rounded */
  font-family: 'GothamRounded' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.header-h5-bold {
  /* Header/H5 - Bold - Gotham Rounded */
  font-family: 'Gotham' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.body-b1-bold {
  /* Body/B1 - Bold - Gotham */
  font-family: 'Gotham' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.body-b2-medium {
  /* Body/B2 - Medium */
  font-family: 'Gotham' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.button-b1-bold-rounded-uppercase {
  /* Button/B1 - Bold - Gotham Rounded */
  font-family: 'GothamRounded' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.button-b2-medium {
  font-family: Gotham;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
}

.button-b2-uppercase {
  font-family: Gotham;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
}

.caption-bold {
  /* Caption/Bold */
  font-family: 'Gotham' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.caption-medium-rounded {
  /* Caption/Gotham Rounded */
  font-family: 'GothamRounded' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.label-bold-uppercase {
  /* Label/Bold CAP */
  font-family: 'Gotham' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.label-medium-uppercase {
  /* Label/Medium CAP */
  font-family: 'Gotham' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.label-medium {
  /* Label/Medium */
  font-family: 'Gotham' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.label-bold {
  /* Label/Bold */
  font-family: 'Gotham' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
}

.label-bold-rounded {
  /* Label/Gotham Rounded */
  font-family: 'GothamRounded' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
}

.label-medium-rounded {
  /* Label/Gotham Rounded */
  font-family: 'GothamRounded' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
}

.label-l2-medium {
  color: #484848;
  font-family: Gotham;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
}

.tag-t1-bold {
  font-family: Gotham;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 120% */
}

:root {
  --text-header-1: #313131;
  --text-body-1: #484848;
  --text-body-2: #aeaeae;
  --text-body-4: #6f6f6f;
  --text-body-5: #8c8c8c;
  --stroke-stroke-2: #dcdcdc;
  --primary-dark-blue: #0050aa;
  --primary-light-blue: #edf5ff;
  --error-text-color: #ff555f;
  --zogo-web-primary-app-color: #1c64f2;
  --zogo-web-primary-app-custom-background: #e8f0fe;
}

.error-text-color {
  color: var(--error-text-color);
}

.text-color-header-1 {
  color: var(--text-header-1);
}

.text-color-body-1 {
  color: var(--text-body-1);
}

.text-color-body-2 {
  color: var(--text-body-2);
}

.text-color-body-4 {
  color: var(--text-body-4);
}

.text-color-body-5 {
  color: var(--text-body-5);
}

.primary-dark-blue {
  color: var(--primary-dark-blue);
}

@font-face {
  font-family: 'GothamRounded';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/GothamRounded/GothamRounded-Medium.otf');
}

@font-face {
  font-family: 'GothamRounded';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/GothamRounded/GothamRounded-Bold.otf');
}

@font-face {
  font-family: 'GothamRounded';
  font-style: italic;
  font-weight: 500;
  src: url('assets/fonts/GothamRounded/GothamRounded-MediumItalic.otf');
}

@font-face {
  font-family: 'GothamRounded';
  font-style: italic;
  font-weight: 600;
  src: url('assets/fonts/GothamRounded/GothamRounded-BoldItalic.otf');
}

@font-face {
  font-family: 'GothamRounded';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/GothamRounded/GothamRounded-Book.otf');
}

//font group 2
@font-face {
  font-family: 'notosans';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/NotoSans/NotoSans-Medium.ttf');
}

@font-face {
  font-family: 'notosans';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/NotoSans/NotoSans-Bold.ttf');
}

@font-face {
  font-family: 'notosans';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/NotoSans/NotoSans-MediumItalic.ttf');
}

@font-face {
  font-family: 'notosans';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/NotoSans/NotoSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'notosans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/NotoSans/NotoSans-Regular.ttf');
}

// font group 3
@font-face {
  font-family: 'raleway';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/Raleway/Raleway-Medium.ttf');
}

@font-face {
  font-family: 'raleway';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/Raleway/Raleway-Bold.ttf');
}

@font-face {
  font-family: 'raleway';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/Raleway/Raleway-MediumItalic.ttf');
}

@font-face {
  font-family: 'raleway';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/Raleway/Raleway-BoldItalic.ttf');
}

@font-face {
  font-family: 'raleway';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Raleway/Raleway-Regular.ttf');
}

// font group 4
@font-face {
  font-family: 'merriweather';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/Merriweather/Merriweather-Regular.ttf');
}

@font-face {
  font-family: 'merriweather';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/Merriweather/Merriweather-Bold.ttf');
}

@font-face {
  font-family: 'merriweather';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/Merriweather/Merriweather-Italic.ttf');
}

@font-face {
  font-family: 'merriweather';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/Merriweather/Merriweather-BoldItalic.ttf');
}

@font-face {
  font-family: 'merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Merriweather/Merriweather-Regular.ttf');
}
