/* You can add global styles to this file, and also import other style files */

@import 'assets/boostrap-grid/grid.min.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'GothamRounded', 'Montserrat', Roboto, 'Helvetica Neue',
    sans-serif !important;
  color: var(--neutral-800);
}

h1,
h2,
h3,
h4,
p {
  font-size: unset;
  font-weight: unset;
}

fieldset {
  border: none;
}

a {
  text-decoration: none;
}

.mat-button.mat-button-disabled {
  background-color: #aaa !important;
}

.dashboard-view {
  padding: 40px;
  max-width: 1304px;
  margin: 0 auto;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
}

.mat-tooltip {
  white-space: pre-line;
}

.dashboard-view .content-area {
  margin-top: 24px;
}

.mat-expansion-panel-header .mat-expansion-indicator::after {
  margin-top: -5px !important;
  color: #fff !important;
}
.mat-expanded .mat-expansion-indicator::after {
  margin-top: -5px !important;
  color: #fff !important;
}

.mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 0px 16px !important;
}

.mat-expansion-panel-header {
  white-space: nowrap !important;
}

.mat-expansion-panel-header {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.mat-expansion-panel-content .mat-expansion-panel-header.with-icon {
  padding: 0px 0 0 16px !important;
}

.analytics-expansion {
  margin-top: 30px;
  border-radius: 5px;
}

.subheader {
  font-family: 'GothamRounded', 'Open Sans', Roboto, 'Helvetica Neue',
    sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0px 0;
  color: #77708e;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  list-style: none;
}

.flex-item {
  text-align: center;
}

.card .card-emblem {
  width: 40px;
  height: 40px;
  margin: 20px auto;
  border-radius: 100px;
}

.card .card-emblem.yellow,
.card .card-cta.yellow {
  background: rgba(255, 164, 28, 0.2);
  color: rgb(255, 164, 28);
  transition: all 0.3s ease-in-out;
}

.card .card-emblem.green,
.card .card-cta.green {
  background: rgba(0, 128, 115, 0.2);
  color: rgb(0, 128, 115);
  transition: all 0.3s ease-in-out;
}

.card .card-emblem.purple,
.card .card-cta.purple {
  background: #e8ddfb;
  color: #8e54e9;
  transition: all 0.3s ease-in-out;
}

.card .card-emblem.blue,
.card .card-cta.blue {
  background: rgba(63, 81, 181, 0.2);
  color: #3f51b5;
  transition: all 0.3s ease-in-out;
}

.card .card-emblem.zogo-blue,
.card .card-cta.zogo-blue {
  background: rgba(41, 142, 255, 0.2);
  color: #298eff;
  transition: all 0.3s ease-in-out;
}

.card .card-emblem svg {
  width: 18px;
  height: 18px;
  margin-top: 11px;
}

.card .card-amount {
  font-weight: 800;
  font-size: 36px;
  color: #0f052f;
}

.card .card-desc {
  font-weight: 600;
  font-size: 16px;
  color: rgba(15, 5, 47, 0.66);
  padding: 14px;
  max-width: 334px;
  margin: 0 auto;
}

.card .card-cta {
  border-radius: 4px;
  font-family: 'GothamRounded', 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 3px 12px;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 6px;
}

button.blue {
  background: #0050aa;
  color: #fff !important;
  font-family: 'GothamRounded', 'Montserrat';
  font-weight: 500;
}

button.gray {
  background: #46455b;
  color: #fff !important;
  font-family: 'GothamRounded', 'Montserrat';
  font-weight: 500;
}

.cta {
  margin-top: 22px;
}

.create-interface-frame {
  width: 520px;
}

.create-interface-frame .header {
  font-weight: 600;
  font-size: 20px;
  color: #25282b;
  margin-bottom: 32px;
}

.header-area .close,
.create-interface-frame .close {
  display: inline-block;
  float: right;
  margin-top: 2px;
  color: #a0a0a0;
  cursor: pointer;
}

.light-btn-style {
  background-color: #fff !important;
  letter-spacing: 1.25px;
  font-weight: 600;
  border-radius: 6px;
  border: 2px solid #0050aa !important;
  color: #0050aa !important;
}

.create-interface-frame button.red {
  background: rgba(233, 30, 99, 0.1);
  color: #e91e63;
}

.mat-slide-toggle-bar {
  position: relative !important;
  width: 40px !important;
  height: 22px !important;
  flex-shrink: 0 !important;
  border-radius: 12px !important;
}

/* Adjust the size of the knob */
.mat-slide-toggle-thumb {
  width: 40px; /* Set the desired width of the knob */
  height: 40px; /* Set the desired height of the knob */
}

.mat-slide-toggle-thumb {
  height: 18px !important;
  width: 18px !important;
  border-radius: 50%;
  display: block;
}

.mat-slide-toggle-thumb-container {
  position: absolute;
  z-index: 1;
  width: 20px !important;
  height: 20px !important;
  top: 1.5px !important;
  left: 3px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fafafa !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #005abe !important;
}

mat-form-field {
  width: 100%;
  font-weight: 600;
}

.tappable {
  cursor: pointer;
}

.emoji-panel-width {
  min-width: 384px !important;
}

.loader {
  position: relative;
  margin: 0 auto;
  width: 50px;
  margin-top: 36px;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: #2f91ff;
  }
  40% {
    stroke: #e91e63;
  }
  66% {
    stroke: #ffa41c;
  }
  80%,
  90% {
    stroke: #009688;
  }
}

.showbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5%;
}

.mat-dialog-container {
  max-height: 780px !important;
  height: unset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent !important;
}

.analytics-expansion
  .mat-expansion-panel-header
  .mat-expansion-indicator::after {
  color: #0f052f !important;
}

.analytics-expansion .mat-expanded .mat-expansion-indicator::after {
  color: #0f052f !important;
}

.messages-body .mat-select-arrow-wrapper {
  vertical-align: sub !important;
  padding-right: 4px !important;
}

.messages-body .mat-select-value-text span {
  font-size: 12px !important;
}

.analytics-expansion mat-expansion-panel {
  margin-top: 30px !important;
  border-radius: 5px;
}

.messages-body .mat-select-value {
  text-align: left;
  padding-left: 8px;
}

.preview-image svg {
  width: 226px;
  height: 446px;
  pointer-events: none;
}

.color-picker .saturation-lightness {
  border-radius: 6px;
}

.color-picker {
  border: 2px solid var(--neutral-300) !important;
  border-radius: 8px;
}

.users-reported-markdown strong {
  font-size: 20px;
  color: #484848;
}

.users-reported-markdown p {
  font-weight: 500;
  color: #484848;
}

.highlights-modal-component .mat-dialog-container {
  padding: 0 !important;
}

.mat-option.institution-select-option {
  height: 80px !important;
}

.mat-drawer-content.mat-sidenav-content:has(.institution-select),
.mat-drawer-container.mat-sidenav-container:has(.institution-select) {
  z-index: unset !important;
}

.cdk-overlay-container:has(.institution-select-backdrop) {
  z-index: 998 !important;
}

.institution-select-backdrop {
  z-index: 998 !important;
  background: rgba(0, 0, 0, 0.32);
}

.custom-radio-button .mat-radio-outer-circle {
  width: 24px; /* Set the desired outer circle width */
  height: 24px; /* Set the desired outer circle height */
  /* background: ; */
}

.custom-radio-button .mat-radio-inner-circle {
  width: 24px; /* Set the desired inner circle width */
  height: 24px; /* Set the desired inner circle height */
}

.custom-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: #484848 !important; /* Set the desired border color for the checked state */
}

.custom-radio-button.mat-radio-checked .mat-radio-inner-circle {
  background-color: #005abe !important; /* Set the desired fill color for the checked state */
  box-shadow: none !important;
}

.custom-radio-button .mat-radio-ripple {
  display: none !important;
}

.testing-env {
  z-index: 999;
  display: inline-block;
  background: #f44336;
  color: #fff;
  position: absolute;
  width: fit-content;
  padding: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 6px;
  font-weight: 600;
  font-size: 12px;
}

.no-scroll-modal .mat-dialog-container {
  overflow-y: hidden;
  padding: 80px 0 94px 0;
  position: relative;
}

.request-pdf-modal-content .mat-dialog-content {
  height: 100%;
  max-height: unset;
}

.snippet-input-area .mat-form-field-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.question-text-field-area .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.cdk-drag-preview {
  display: none !important;
}

.markdown-cell-description {
  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.mat-radio-label-content {
  color: var(--neutral-800);
  /* Body/medium */
  font-family: 'Gotham' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

zogo-btn {
  flex-shrink: 0;
}

.overview-widget-loader {
  margin: 10vw;
}

.hero-text-item p {
  margin-bottom: 16px;
}

.svg-height {
  height: 100%;
  width: 100%;
}

.noto-sans-font p {
  font-family: 'notosans';
}

.raleway-font p {
  font-family: 'raleway';
}

.merriweather-font p {
  font-family: 'merriweather';
}

.hero-text-item a {
  color: var(--neutral-white);
}

.alert-modal {
  --border-radius: 16px;
  --height: auto;
}

@media (hover: none) {
  .navigation-area
    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
    .mat-expansion-panel-header:hover {
    background: none;
  }
}

ion-spinner {
  width: 34px;
  height: 34px;
  margin-top: 24px;
  margin-bottom: 24px;
  --color: var(--shades-primary-1);
}

.marketing-card-description ul {
  padding-left: 20px;
}
