// design system typography

:root {
  // This area is for color variables
}

// This area is for design system styles and fonts ONLY
.display2-book {
  /* Display 2/Book */
  font-family: 'Gotham';
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 100% */
}

.display2-medium {
  /* Display 2/Medium */
  font-family: 'Gotham';
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px; /* 100% */
}

.display2-bold {
  /* Display 2/Bold */
  font-family: 'Gotham';
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 100% */
}

.display3-book {
  /* Display 3/Book */
  font-family: 'Gotham';
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 104.167% */
}

.display3-medium {
  /* Display 3/Medium */
  font-family: 'Gotham';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 104.167% */
}

.display3-bold {
  /* Display 3/Bold */
  font-family: 'Gotham';
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 104.167% */
}

.h1-book {
  /* H1/Book */
  font-family: 'Gotham';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
}

.h1-medium {
  /* H1/medium */
  font-family: 'Gotham';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; /* 120% */
}

.h1-bold {
  /* H1/bold */
  font-family: 'Gotham';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
}

.h2-book {
  /* H2/Book */
  font-family: 'Gotham';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
  letter-spacing: -0.25px;
}

.h2-medium {
  /* H2/medium */
  font-family: 'Gotham';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
  letter-spacing: -0.25px;
}

.h2-bold {
  /* H2/bold */
  font-family: 'Gotham';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  letter-spacing: -0.25px;
}

.h3-book {
  /* H3/Book */
  font-family: 'Gotham';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 114.286% */
  letter-spacing: -0.25px;
}

.h3-medium {
  /* H3/medium */
  font-family: 'Gotham';
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 114.286% */
  letter-spacing: -0.25px;
}

.h3-bold {
  /* H3/Book */
  font-family: 'Gotham';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 114.286% */
  letter-spacing: -0.25px;
}

.h4-book {
  /* H4/Book */
  font-family: 'Gotham';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
  letter-spacing: 0.15px;
}

.h4-medium {
  /* H4/medium */
  font-family: 'Gotham';
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 127.273% */
  letter-spacing: 0.15px;
}

.h4-bold {
  /* H4/bold */
  font-family: 'Gotham';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 127.273% */
  letter-spacing: 0.15px;
}

.h5-book {
  /* H5/Book */
  font-family: 'Gotham';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.h5-medium {
  /* H5/medium */
  font-family: 'Gotham';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}

.h5-bold {
  /* H5/bold */
  font-family: 'Gotham';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.body-large-book {
  /* Body Large/Book */
  font-family: 'Gotham';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.body-large-medium {
  /* Body Large/medium */
  font-family: 'Gotham';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.body-large-bold {
  /* Body Large/bold */
  font-family: 'Gotham';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}

.body-book {
  /* Body/Book */
  font-family: 'Gotham';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.body-medium {
  /* Body/medium */
  font-family: 'Gotham';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.body-bold {
  /* Body/bold */
  font-family: 'Gotham';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.caption-book {
  /* Caption/Book */
  font-family: 'Gotham';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.caption-medium {
  /* Caption/medium */
  font-family: 'Gotham';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.caption-bold {
  /* Caption/bold */
  font-family: 'Gotham';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.overline-book {
  /* Overline/Book */
  font-family: 'Gotham';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.overline-medium {
  /* Overline/medium */
  font-family: 'Gotham';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.overline-bold {
  /* Overline/bold */
  font-family: 'Gotham';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
}

// Mobile Font Classes
@media screen and (max-width: 576px) {
  .display2-book {
    /* Display 2/Mobile - Book */
    font-family: 'Gotham';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 100% */
  }

  .display2-medium {
    /* Display 2/Mobile - Medium */
    font-family: 'Gotham';
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 100% */
  }

  .display2-bold {
    /* Display 2/Mobile - Bold */
    font-family: 'Gotham';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 100% */
  }

  .display3-book {
    /* Display 3/Mobile - Book */
    font-family: 'Gotham';
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 111.111% */
  }

  .display3-medium {
    /* Display 3/Mobile - Medium */
    font-family: 'Gotham';
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 111.111% */
  }

  .display3-bold {
    /* Display 3/Mobile - Bold */
    font-family: 'Gotham';
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 111.111% */
  }

  .h1-book {
    /* H1/Mobile - Book */
    font-family: 'Gotham';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
  }

  .h1-medium {
    /* H1/Mobile - Medium */
    font-family: 'Gotham';
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 125% */
  }

  .h1-bold {
    /* H1/Mobile - Bold */
    font-family: 'Gotham';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
  }

  .h2-book {
    /* H2/Mobile - Book */
    font-family: 'Gotham';
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 111.111% */
    letter-spacing: -0.25px;
  }

  .h2-medium {
    /* H2/Mobile - Medium */
    font-family: 'Gotham';
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 111.111% */
    letter-spacing: -0.25px;
  }

  .h2-bold {
    /* H2/Mobile - Bold */
    font-family: 'Gotham';
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 111.111% */
    letter-spacing: -0.25px;
  }
}

ion-toast {
  --width: fit-content;
  --background: var(--neutral-600);
  --border-radius: 6px;
  --box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.08);
}
