@import 'node_modules/@angular/material/theming';
@include mat-core();

/* ======== Angular material custom themes ======== */
$my-custom-primary: mat-palette($mat-blue);
$my-custom-accent: mat-palette($mat-teal);
$my-custom-warn: mat-palette($mat-teal);

$my-custom-theme: mat-light-theme(
  $my-custom-primary,
  $my-custom-accent,
  $my-custom-warn
);
$custom-typography: mat-typography-config(
  $font-family: 'GothamRounded',
);

@include angular-material-typography($custom-typography);
@include angular-material-theme($my-custom-theme);
