/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
  padding: 0;
}

/*
  3. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

// Design system typography
:root {
  // Color variables
  --brand-primary: #1c64f2;
  --tints-primary-1: #ebf5ff;
  --tints-primary-2: #c3ddfd;
  --tints-primary-3: #76a9fa;
  --shades-primary-1: #233876;
  --shades-primary-2: #1a223a;

  --brand-green: #20cb99;
  --tints-green-1: #e9faf5;
  --shades-green-1: #148060;

  --brand-gold: #faca15;
  --tints-gold-1: #fefae8;
  --shades-gold-1: #886e0c;

  --brand-orange: #ff5a1f;
  --tints-orange-1: #fff3ee;
  --shades-orange-1: #bf4417;

  --utility-red: #ed3731;
  --utility-red-tint-1: #fef0ef;
  --utility-red-shade-1: #c51711;

  --neutral-white: #ffffff;
  --neutral-100: #f6f6f6;
  --neutral-200: #ececec;
  --neutral-300: #dcdcdc;
  --neutral-400: #acacac;
  --neutral-500: #8c8c8c;
  --neutral-600: #6f6f6f;
  --neutral-700: #484848;
  --neutral-800: #313131;
  --neutral-warm-100: #faf9f5;

  // Grid Variables
  --max-width-mobile: 576px;
  --max-width-tablet: 834px;
  --max-width-desktop: 1224px;
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Gotham/Gotham-Book.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/Gotham/Gotham-BookItalic.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Gotham/Gotham-Medium.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/Gotham/Gotham-MediumItalic.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Gotham/Gotham-Bold.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/Gotham/Gotham-BoldItalic.otf');
}

[tappable] {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

// Background Color Classes
.background-neutral-white {
  background-color: var(--neutral-white);
}

.background-neutral-100 {
  background-color: var(--neutral-100);
}

.background-neutral-200 {
  background-color: var(--neutral-200);
}

.background-neutral-300 {
  background-color: var(--neutral-300);
}

.background-neutral-400 {
  background-color: var(--neutral-400);
}

.background-neutral-500 {
  background-color: var(--neutral-500);
}

.background-neutral-600 {
  background-color: var(--neutral-600);
}

.background-neutral-700 {
  background-color: var(--neutral-700);
}

.background-neutral-800 {
  background-color: var(--neutral-800);
}

.background-neutral-warm-100 {
  background-color: var(--neutral-warm-100);
}

.background-brand-primary {
  background-color: var(--brand-primary);
}

.background-tints-primary-1 {
  background-color: var(--tints-primary-1);
}

.background-tints-primary-2 {
  background-color: var(--tints-primary-2);
}

.background-tints-primary-3 {
  background-color: var(--tints-primary-3);
}

.background-shades-primary-1 {
  background-color: var(--shades-primary-1);
}

.background-shades-primary-2 {
  background-color: var(--shades-primary-2);
}

.background-brand-green {
  background-color: var(--brand-green);
}

.background-tints-green-1 {
  background-color: var(--tints-green-1);
}

.background-shades-green-1 {
  background-color: var(--shades-green-1);
}

.background-brand-gold {
  background-color: var(--brand-gold);
}

.background-tints-gold-1 {
  background-color: var(--tints-gold-1);
}

.background-shades-gold-1 {
  background-color: var(--shades-gold-1);
}

.background-brand-orange {
  background-color: var(--brand-orange);
}

.background-tints-orange-1 {
  background-color: var(--tints-orange-1);
}

.background-shades-orange-1 {
  background-color: var(--shades-orange-1);
}

.background-utility-red {
  background-color: var(--utility-red);
}

.background-utility-red-shade-1 {
  background-color: var(--utility-red-shade-1);
}

.background-utility-red-tint-1 {
  background-color: var(--utility-red-tint-1);
}

// Text Color Classes
.text-color-neutral-white {
  color: var(--neutral-white);
}

.text-color-neutral-100 {
  color: var(--neutral-100);
}

.text-color-neutral-200 {
  color: var(--neutral-200);
}

.text-color-neutral-300 {
  color: var(--neutral-300);
}

.text-color-neutral-400 {
  color: var(--neutral-400);
}

.text-color-neutral-500 {
  color: var(--neutral-500);
}

.text-color-neutral-600 {
  color: var(--neutral-600);
}

.text-color-neutral-700 {
  color: var(--neutral-700);
}

.text-color-neutral-800 {
  color: var(--neutral-800);
}

.text-color-neutral-warm-100 {
  color: var(--neutral-warm-100);
}

.text-color-brand-primary {
  color: var(--brand-primary);
}

.text-color-tints-primary-1 {
  color: var(--tints-primary-1);
}

.text-color-tints-primary-2 {
  color: var(--tints-primary-2);
}

.text-color-tints-primary-3 {
  color: var(--tints-primary-3);
}

.text-color-shades-primary-1 {
  color: var(--shades-primary-1);
}

.text-color-shades-primary-2 {
  color: var(--shades-primary-2);
}

.text-color-brand-green {
  color: var(--brand-green);
}

.text-color-tints-green-1 {
  color: var(--tints-green-1);
}

.text-color-shades-green-1 {
  color: var(--shades-green-1);
}

.text-color-brand-gold {
  color: var(--brand-gold);
}

.text-color-tints-gold-1 {
  color: var(--tints-gold-1);
}

.text-color-shades-gold-1 {
  color: var(--shades-gold-1);
}

.text-color-brand-orange {
  color: var(--brand-orange);
}

.text-color-tints-orange-1 {
  color: var(--tints-orange-1);
}

.text-color-shades-orange-1 {
  color: var(--shades-orange-1);
}

.text-color-utility-red {
  color: var(--utility-red);
}

.text-color-utility-red-shade-1 {
  color: var(--utility-red-shade-1);
}

.text-color-utility-red-tint-1 {
  color: var(--utility-red-tint-1);
}

// Margin Classes
.margin-2 {
  margin: 2px;
}

.margin-x-2,
.margin-right-2 {
  margin-right: 2px;
}

.margin-x-2,
.margin-left-2 {
  margin-left: 2px;
}

.margin-y-2,
.margin-top-2 {
  margin-top: 2px;
}

.margin-y-2,
.margin-bottom-2 {
  margin-bottom: 2px;
}

.margin-4 {
  margin: 4px;
}

.margin-x-4,
.margin-right-4 {
  margin-right: 4px;
}

.margin-x-4,
.margin-left-4 {
  margin-left: 4px;
}

.margin-y-4,
.margin-top-4 {
  margin-top: 4px;
}

.margin-y-4,
.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-8 {
  margin: 8px;
}

.margin-x-8,
.margin-right-8 {
  margin-right: 8px;
}

.margin-x-8,
.margin-left-8 {
  margin-left: 8px;
}

.margin-y-8,
.margin-top-8 {
  margin-top: 8px;
}

.margin-y-8,
.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-12 {
  margin: 12px;
}

.margin-x-12,
.margin-right-12 {
  margin-right: 12px;
}

.margin-x-12,
.margin-left-12 {
  margin-left: 12px;
}

.margin-y-12,
.margin-top-12 {
  margin-top: 12px;
}

.margin-y-12,
.margin-bottom-12 {
  margin-bottom: 12px;
}

.margin-16 {
  margin: 16px;
}

.margin-x-16,
.margin-right-16 {
  margin-right: 16px;
}

.margin-x-16,
.margin-left-16 {
  margin-left: 16px;
}

.margin-y-16,
.margin-top-16 {
  margin-top: 16px;
}

.margin-y-16,
.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-24 {
  margin: 24px;
}

.margin-x-24,
.margin-right-24 {
  margin-right: 24px;
}

.margin-x-24,
.margin-left-24 {
  margin-left: 24px;
}

.margin-y-24,
.margin-top-24 {
  margin-top: 24px;
}

.margin-y-24,
.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-32 {
  margin: 32px;
}

.margin-x-32,
.margin-right-32 {
  margin-right: 32px;
}

.margin-x-32,
.margin-left-32 {
  margin-left: 32px;
}

.margin-y-32,
.margin-top-32 {
  margin-top: 32px;
}

.margin-y-32,
.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-48 {
  margin: 48px;
}

.margin-x-48,
.margin-right-48 {
  margin-right: 48px;
}

.margin-x-48,
.margin-left-48 {
  margin-left: 48px;
}

.margin-y-48,
.margin-top-48 {
  margin-top: 48px;
}

.margin-y-48,
.margin-bottom-48 {
  margin-bottom: 48px;
}

.margin-64 {
  margin: 64px;
}

.margin-x-64,
.margin-right-64 {
  margin-right: 64px;
}

.margin-x-64,
.margin-left-64 {
  margin-left: 64px;
}

.margin-y-64,
.margin-top-64 {
  margin-top: 64px;
}

.margin-y-64,
.margin-bottom-64 {
  margin-bottom: 64px;
}

.margin-80 {
  margin: 80px;
}

.margin-x-80,
.margin-right-80 {
  margin-right: 80px;
}

.margin-x-80,
.margin-left-80 {
  margin-left: 80px;
}

.margin-y-80,
.margin-top-80 {
  margin-top: 80px;
}

.margin-y-80,
.margin-bottom-80 {
  margin-bottom: 80px;
}

.margin-120 {
  margin: 120px;
}

.margin-x-120,
.margin-right-120 {
  margin-right: 120px;
}

.margin-x-120,
.margin-left-120 {
  margin-left: 120px;
}

.margin-y-120,
.margin-top-120 {
  margin-top: 120px;
}

.margin-y-120,
.margin-bottom-120 {
  margin-bottom: 120px;
}

// Padding Classes
.padding-4 {
  padding: 4px;
}

.padding-x-4,
.padding-right-4 {
  padding-right: 4px;
}

.padding-x-4,
.padding-left-4 {
  padding-left: 4px;
}

.padding-y-4,
.padding-top-4 {
  padding-top: 4px;
}

.padding-y-4,
.padding-bottom-4 {
  padding-bottom: 4px;
}

.padding-8 {
  padding: 8px;
}

.padding-x-8,
.padding-right-8 {
  padding-right: 8px;
}

.padding-x-8,
.padding-left-8 {
  padding-left: 8px;
}

.padding-y-8,
.padding-top-8 {
  padding-top: 8px;
}

.padding-y-8,
.padding-bottom-8 {
  padding-bottom: 8px;
}

.padding-12 {
  padding: 12px;
}

.padding-x-12,
.padding-right-12 {
  padding-right: 12px;
}

.padding-x-12,
.padding-left-12 {
  padding-left: 12px;
}

.padding-y-12,
.padding-top-12 {
  padding-top: 12px;
}

.padding-y-12,
.padding-bottom-12 {
  padding-bottom: 12px;
}

.padding-16 {
  padding: 16px;
}

.padding-x-16,
.padding-right-16 {
  padding-right: 16px;
}

.padding-x-16,
.padding-left-16 {
  padding-left: 16px;
}

.padding-y-16,
.padding-top-16 {
  padding-top: 16px;
}

.padding-y-16,
.padding-bottom-16 {
  padding-bottom: 16px;
}

.padding-24 {
  padding: 24px;
}

.padding-x-24,
.padding-right-24 {
  padding-right: 24px;
}

.padding-x-24,
.padding-left-24 {
  padding-left: 24px;
}

.padding-y-24,
.padding-top-24 {
  padding-top: 24px;
}

.padding-y-24,
.padding-bottom-24 {
  padding-bottom: 24px;
}

.padding-32 {
  padding: 32px;
}

.padding-x-32,
.padding-right-32 {
  padding-right: 32px;
}

.padding-x-32,
.padding-left-32 {
  padding-left: 32px;
}

.padding-y-32,
.padding-top-32 {
  padding-top: 32px;
}

.padding-y-32,
.padding-bottom-32 {
  padding-bottom: 32px;
}

.padding-48 {
  padding: 48px;
}

.padding-x-48,
.padding-right-48 {
  padding-right: 48px;
}

.padding-x-48,
.padding-left-48 {
  padding-left: 48px;
}

.padding-y-48,
.padding-top-48 {
  padding-top: 48px;
}

.padding-y-48,
.padding-bottom-48 {
  padding-bottom: 48px;
}

.padding-96 {
  padding: 96px;
}

.padding-x-96,
.padding-right-96 {
  padding-right: 96px;
}

.padding-x-96,
.padding-left-96 {
  padding-left: 96px;
}

.padding-y-96,
.padding-top-96 {
  padding-top: 96px;
}

.padding-y-96,
.padding-bottom-96 {
  padding-bottom: 96px;
}

.padding-120 {
  padding: 120px;
}

.padding-x-120,
.padding-right-120 {
  padding-right: 120px;
}

.padding-x-120,
.padding-left-120 {
  padding-left: 120px;
}

.padding-y-120,
.padding-top-120 {
  padding-top: 120px;
}

.padding-y-120,
.padding-bottom-120 {
  padding-bottom: 120px;
}

.pos-rel {
  position: relative;
}

// Display Block
.d-block {
  display: block;
}

// Flex Classes
.d-flex-row {
  display: flex;
  flex-direction: row;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.width-100 {
  width: 100%;
}

.gap-4 {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.gap-48 {
  gap: 48px;
}

.gap-64 {
  gap: 64px;
}

.gap-72 {
  gap: 64px;
}

.gap-80 {
  gap: 80px;
}

.gap-128 {
  gap: 128px;
}

.flex-grow {
  flex-grow: 1;
}

// Flex Utility Classes
.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

// Border Classes
.border {
  border: 1px solid;
}

.border-top {
  border-top: 1px solid var(--neutral-200);
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-16 {
  border-radius: 16px;
}

.border-radius-24 {
  border-radius: 16px;
}

.accent-line {
  flex-grow: 1;
  border-bottom: 1px solid;
}

.border-neutral-white {
  border-color: var(--neutral-white);
}

.border-neutral-200 {
  border-color: var(--neutral-200);
}

.border-neutral-300 {
  border-color: var(--neutral-300);
}

.border-neutral-800 {
  border-color: var(--neutral-800);
}

.border-brand-primary {
  border-color: var(--brand-primary);
}

.border-tints-primary-1 {
  border-color: var(--tints-primary-1);
}

.border-tints-primary-2 {
  border-color: var(--tints-primary-2);
}

.border-tints-primary-3 {
  border-color: var(--tints-primary-3);
}

.border-shades-primary-1 {
  border-color: var(--shades-primary-1);
}

.border-shades-primary-2 {
  border-color: var(--shades-primary-2);
}

.border-brand-green {
  border-color: var(--brand-green);
}

.border-brand-gold {
  border-color: var(--brand-gold);
}

.border-brand-orange {
  border-color: var(--brand-orange);
}

// Mobile device classes
@media screen and (max-width: 576px) {
  .m-flex-row {
    display: flex;
    flex-direction: row;
  }

  .m-flex-col {
    display: flex;
    flex-direction: column;
  }

  // Mobile padding classes
  .m-padding-0 {
    padding: 0px;
  }

  .m-padding-x-0,
  .m-padding-right-0 {
    padding-right: 0px;
  }

  .m-padding-x-0,
  .m-padding-left-0 {
    padding-left: 0px;
  }

  .m-padding-y-0,
  .m-padding-top-0 {
    padding-top: 0px;
  }

  .m-padding-y-0,
  .m-padding-bottom-0 {
    padding-bottom: 0px;
  }

  .m-padding-16 {
    padding: 16px;
  }

  .m-padding-x-16,
  .m-padding-right-16 {
    padding-right: 16px;
  }

  .m-padding-x-16,
  .m-padding-left-16 {
    padding-left: 16px;
  }

  .m-padding-y-16,
  .m-padding-top-16 {
    padding-top: 16px;
  }

  .m-padding-y-16,
  .m-padding-bottom-16 {
    padding-bottom: 16px;
  }

  .m-padding-120 {
    padding: 120px;
  }

  .m-padding-x-120,
  .m-padding-right-120 {
    padding-right: 120px;
  }

  .m-padding-x-120,
  .m-padding-left-120 {
    padding-left: 120px;
  }

  .m-padding-y-120,
  .m-padding-top-120 {
    padding-top: 120px;
  }

  .m-padding-y-120,
  .m-padding-bottom-120 {
    padding-bottom: 120px;
  }
  // Mobile margin classes
  .m-margin-0 {
    margin: 0px;
  }

  .m-margin-x-0,
  .m-margin-right-0 {
    margin-right: 0px;
  }

  .m-margin-x-0,
  .m-margin-left-0 {
    margin-left: 0px;
  }

  .m-margin-y-0,
  .m-margin-top-0 {
    margin-top: 0px;
  }

  .m-margin-y-0,
  .m-margin-bottom-0 {
    margin-bottom: 0px;
  }

  .m-margin-48 {
    margin: 48px;
  }

  .m-margin-x-48,
  .m-margin-right-48 {
    margin-right: 48px;
  }

  .m-margin-x-48,
  .m-margin-left-48 {
    margin-left: 48px;
  }

  .m-margin-y-48,
  .m-margin-top-48 {
    margin-top: 48px;
  }

  .m-margin-y-48,
  .m-margin-bottom-48 {
    margin-bottom: 48px;
  }

  .m-margin-64 {
    margin: 64px;
  }

  .m-margin-x-64,
  .m-margin-right-64 {
    margin-right: 64px;
  }

  .m-margin-x-64,
  .m-margin-left-64 {
    margin-left: 64px;
  }

  .m-margin-y-64,
  .m-margin-top-64 {
    margin-top: 64px;
  }

  .m-margin-y-64,
  .m-margin-bottom-64 {
    margin-bottom: 64px;
  }

  .m-margin-120 {
    margin: 120px;
  }

  .m-margin-x-120,
  .m-margin-right-120 {
    margin-right: 120px;
  }

  .m-margin-x-120,
  .m-margin-left-120 {
    margin-left: 120px;
  }

  .m-margin-y-120,
  .m-margin-top-120 {
    margin-top: 120px;
  }

  .m-margin-y-120,
  .m-margin-bottom-120 {
    margin-bottom: 120px;
  }

  // Mobile gap classes
  .m-gap-16 {
    gap: 16px;
  }

  .m-gap-24 {
    gap: 24px;
  }

  .m-gap-48 {
    gap: 48px;
  }

  .m-gap-64 {
    gap: 64px;
  }
}
